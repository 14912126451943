<template>
    <div class="news-container g-page-list">
      <!-- 侧边栏 -->
      <!-- <el-scrollbar
        y
        class="g-bg g-mr-10"
        style=" width: 240px;"
      >
        <category-view @change="onCategoryChange"></category-view>
      </el-scrollbar> -->
  
      <!-- 主内容区域 -->
      <el-scrollbar y class="g-bg g-flex-fill">
        <div class="g-p-20">
          <el-form ref="form" :model="searchData" label-position="top" inline>
  
            <el-form-item label="标题" prop="title">
              <el-input v-model="searchData.title" placeholder="输入内容" clearable></el-input>
            </el-form-item>
            <el-form-item label="来源" prop="author">
              <el-input v-model="searchData.author" placeholder="输入内容" clearable></el-input>
            </el-form-item>
            <el-form-item label="发布时间" prop="time">
              <el-date-picker v-model="searchData.time"
                              type="daterange"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              clearable></el-date-picker>
            </el-form-item>
            <el-form-item style="vertical-align: bottom;">
              <c-btn-search @search="onRefresh" @reset="onReset" @create="onAdd"></c-btn-search>
            </el-form-item>
          </el-form>
  
  
          <el-table :data="list">
            <el-table-column label="ID" prop="id" width="80"></el-table-column>
            <el-table-column label="类型"  width="120" >
              <template slot-scope="{ row }">
                <span v-for="item in row.columns" :key="item.id">{{item.name + " " }}</span>
              </template>
            </el-table-column>
            <el-table-column label="标题" prop="title"></el-table-column>
            <el-table-column label="来源" prop="author" width="180"></el-table-column>
            <el-table-column label="发布日期" prop="created_at" width="180"></el-table-column>
            <el-table-column label="操作" width="180" align="center">
              <template slot-scope="{ row }">
                <c-btn-table :show="[
                    'info',
                    row.user && userId == row.user.id ? 'update' : '',
                    row.user && userId == row.user.id ? 'delete' : '',
                  ]"
                             @info="onView(row)"
                             @update="onEdit(row)"
                             @delete="onDelete(row)"></c-btn-table>
              </template>
            </el-table-column>
          </el-table>
  
          <b-pagination :page="page" @change="fetchList"></b-pagination>
        </div>
      </el-scrollbar>
    </div>
  </template>
  
  <script>
  // import CategoryView from '@/components/tree/news-category'
  import { getNewsPage, deleteNews, getPostsColumn } from '@/api/news'
  export default {
    components: {
      // CategoryView,
    },
    data() {
      return {
        list: [],
        currentCategory: '',
        page: {
          current: 1,
          size: 15,
          total: 0,
        },
        searchData: {
          title: '',
          author: '',
          time: '',
        },
        infoVisible: false,
  
        // 分类查询
        columnData: [],
      }
    },
    computed: {
      userId() {
        return this.$store.getters['user/id']
      },
      deptId() {
        return this.$store.getters['user/deptId']
      },
    },
    created() {
      this.fetchList()
    },
    methods: {
      fetchList() {
        const parmas = {
          page: this.page.current,
          department_id: this.deptId,
        }
        getNewsPage(2, parmas).then(res => {
          this.list = res.data
          this.page.total = res.total
        })
      },
      onRefresh() {
        this.page.current = 1
        this.fetchList()
      },
      onReset() {
        this.$refs.form.resetFields()
        this.onRefresh()
      },
      onDelete(row) {
        this.$confirm('确定要删除当前项吗', '提示', { type: 'warning' })
          .then(() => {
            return deleteNews(row.id)
          })
          .then(() => {
            this.$message.success('删除成功')
            this.onRefresh()
          })
          .catch(() => {})
      },
      onAdd() {
        this.$router.push({ path: '/news/worksCreate' })
      },
      onEdit(row) {
        console.log(111)
        this.$router.push({
          path: '/news/worksUpdate',
          query: { id: row.id },
        })
      },
      onView(row) {
        this.$router.push({ path: '/news/worksInfo', query: { id: row.id } })
      },
  
      onGetPostsColumn() {
        this.submitLoading = true
        getPostsColumn()
          .then(res => {
            this.columnData = res
          })
          .finally(() => {
            this.submitLoading = false
          })
      },
  
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .news-container {
    display: flex;
    height: 100%;
    padding: 0 15px 15px;
  }
  </style>
  